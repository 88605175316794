@import '../../styles/core';

.link {
  --chat-link-padding-y: 1.5rem;
  display: block;
  padding-top: var(--chat-link-padding-y);
  padding-bottom: var(--chat-link-padding-y);
  color: inherit;

  &:hover {
    background-color: $light;
    color: inherit;
  }
}

.grid {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 0.75rem;
}

.content {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  column-gap: 0.75rem;
  position: relative;

  &::after {
    display: block;
    @include position(absolute, $bottom: calc(var(--chat-link-padding-y) * -1));
    width: 100%;
    border-bottom: $border-width solid $border-color;
    content: '';
  }

  span {
    display: block;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.message {
  color: $body-color-light;
}
