@import '../../styles/core';

.link {
  display: block;
  padding: 1rem;
  color: inherit;

  &:hover {
    background-color: $light;
    color: inherit;
  }
}

.grid {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 0.75rem;
}

.text {
  color: $body-color-light;
}
