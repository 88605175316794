@import '../../styles/core';

.root {
  padding: 0.875rem 0;
  background-color: $white;
}

.content {
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
}

.user {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.navigation {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}
