@import '../../styles/core';

.root {
  @include aspect-ratio(1, 1);
  width: map-get($avatar-sizes, md);

  &.sm {
    width: map-get($avatar-sizes, sm);
  }

  &.lg {
    width: map-get($avatar-sizes, lg);
  }
}

.avatarText {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba($primary, 0.2);
  color: $primary;
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
}
