/************************************
  Variables
************************************/

/* ------- Colors ------- */
$primary: #5059bf;
$secondary: #4b546d;
$secondary-light: rgba($secondary, 0.2);
$tertiary: #f1f1fa;
$light: #f5f7fb;
$white: #fff;
$red: #dc3545;
$green: #198754;

$text-muted: #adb4c2;

$shadow-color: rgba(57, 61, 115, 0.08);

$body-bg: $light;
$body-color: $secondary;
$body-color-light: rgba($body-color, 0.68);

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  light: $light,
  success: $green,
  danger: $red,
);

/* ------- Typography ------- */
$font-family-base: 'Roboto', sans-serif;
$font-size-base: 0.875rem;
$font-size-sm: 0.75rem;
$font-size-lg: 1.25rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

$line-height-base: 1.2;
$line-height-sm: 1.15;
$line-height-lg: 2;

$h1-font-size: 2.5rem;

$headings-margin-bottom: 1rem;
$headings-font-weight: $font-weight-semibold;
$headings-line-height: $line-height-base;

/* ------- Links ------- */
$link-color: $primary;
$link-decoration: none;

/* ------- Spacers ------- */
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
) !default;

/* ------- Grid Containers ------- */
$gutter-width: 16px;
$gutter-width-lg: 32px;

/* ------- Borders ------- */
$border-width: 1px;
$border-color: $secondary-light;

$border-radius: 0.25rem;
$border-radius-sm: 0.125rem;
$border-radius-lg: 0.75rem;

/* ------- Box Shadow ------- */
$box-shadow: 0 0.25rem 2.5rem $shadow-color;
$box-shadow-sm: 0 0.25rem 1.5rem $shadow-color;

/* ------- Transition ------- */
$transition-duration: 0.3s;
$transition-base: $transition-duration;

/* ------- Buttons ------- */
$btn-padding-y: 1rem;
$btn-padding-x: 2rem;
$btn-line-height: 1;
$btn-font-weight: $font-weight-semibold;
$btn-text-transform: uppercase;
$btn-transition: color $transition-duration,
  background-color $transition-duration, border-color $transition-duration,
  box-shadow $transition-duration;

/* ------- Forms ------- */
$input-padding-y: 0.875rem;
$input-padding-x: 0.75rem;
$input-bg: $white;
$input-border-color: $border-color;
$input-placeholder-color: $text-muted;

/* ------- Sections ------- */
$section-padding-y-spacers: (
  xs: 2.5rem,
  md: 3.5rem,
);

/* ------- Avatar ------- */
$avatar-sizes: (
  sm: 2.25rem,
  md: 2.5rem,
  lg: 3rem,
);
