/************************************
  Mixins
************************************/

/* ------- Position ------- */
@mixin position(
  $position,
  $top: null,
  $left: null,
  $right: null,
  $bottom: null
) {
  position: $position;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

/* ------- Aspect Ratio ------- */
@mixin aspect-ratio($width: 1, $height: 1, $target: null) {
  position: relative;

  &::before {
    display: block;
    padding-top: calc($height / $width * 100%);
    content: '';
  }

  @if $target {
    #{$target} {
      @include position(absolute, 0, 0);
      width: 100%;
      height: 100%;
      @content;
    }
  }
}
