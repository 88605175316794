// Google Fonts
// https://fonts.google.com/share?selection.family=Roboto:wght@300;400;500;700
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// Core Styles
@import './styles/core';
// Bootstrap
@import '~bootstrap/scss/bootstrap';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
ul,
ol {
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  transition: $transition-base;
}

.fs-base {
  font-size: $font-size-base !important;
}

.fs-sm {
  font-size: $font-size-sm !important;
}

.fs-lg {
  font-size: $font-size-lg !important;
}

.text-inherit {
  color: inherit !important;
}

// Images
img {
  max-width: 100%;
}

// Buttons
button {
  all: unset;
  box-sizing: border-box;
}

.btn {
  text-transform: $btn-text-transform;
}

// Containers
.container-fluid-px-lg {
  width: 100%;
  padding-left: $gutter-width-lg;
  padding-right: $gutter-width-lg;
}

.container-inner-sm {
  max-width: 380px;
  margin: 0 auto;
}

// Forms
.form {
  display: grid;
  row-gap: 1rem;
  text-align: left;
}

.section {
  @each $breakpoint, $padding-y in $section-padding-y-spacers {
    @include media-breakpoint-up($breakpoint) {
      padding: $padding-y 0;
    }
  }
}

// Aspect Ratio
.ratio-content,
.ratio-image {
  @include position(absolute, 0, 0);
  width: 100%;
  height: 100%;
}

.ratio-image {
  object-fit: cover;
}
