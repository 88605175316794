@import '../../styles/core';

.root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 400px 1fr;
  @include position(fixed, 0, 0);
  width: 100%;
  height: 100%;
}
